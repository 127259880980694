<template>
  <default-layout>
    <v-container id="registration-management" fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <detail :detail="detail" :permissions="permissions" :allowedActions="allowedActions"></detail>
      <registrant-list
        :items="registrants"
        :meta="registrantMeta.pagination"
        :permissions="permissions"
        @changePage="changePage"
        @changeDateFilter="changeDateFilter"
        @changeStatusFilter="changeStatusFilter"
      ></registrant-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/registration-management/referral-program/detail');
const RegistrantList = () =>
  import('@/components/registration-management/referral-program/registrant-list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
    RegistrantList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
      minDateFilter: null,
      maxDateFilter: null,
      statusFilter: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.referralProgram.permissions,
      allowedActions: (state) => state.referralProgram.allowedActions,
      detail: (state) => state.referralProgram.detail,
      registrants: (state) => state.referralProgram.registrants,
      registrantMeta: (state) => state.referralProgram.registrantMeta,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async loadDetail(query) {
      try {
        this.isLoading = true;
        this.$store.commit('referralProgram/RESET_DEFAULT');
        const response = await this.$store.dispatch('referralProgram/getDetailInitData', {
          uuid: this.$route.params.uuid,
          query: query,
        });
        console.log('DETAIL PROGRAM REFERRAL: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      query.status_filter = this.statusFilter;
      query.min_date = this.minDateFilter;
      query.max_date = this.maxDateFilter;
      await this.loadDetail(query);
    },
    async changeDateFilter(val) {
      let query = this.$route.query;
      query.page = 1;

      this.minDateFilter = query.min_date = val ? val[0] : null;
      this.maxDateFilter = query.max_date = val ? val[1] : null;
      await this.loadDetail(query);
    },
    async changeStatusFilter(val) {
      let query = this.$route.query;
      query.page = 1;

      this.statusFilter = query.status_filter = val;
      await this.loadDetail(query);
    },
  },
};
</script>
